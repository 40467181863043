/* eslint-disable no-plusplus */
import _ from 'lodash'
import { navigate } from 'gatsby'
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import { makeRequest } from '../../helpers'
import getAllUrlParams from '../../helpers/get-query-params'
import { useRooms } from '../../hooks/use-rooms'
import { useBooking } from '../../hooks/use-booking'
import { useTranslation } from 'react-i18next'

import BookingView from './booking-view'

const BookingController = ({ location }) => {
  const { href } = location
  const parametres = getAllUrlParams(href)
  const {
    params,
  } = useRooms()
  const {
    roomsAvailables,
    getAvailableRooms,
    loading,
    totalPerson,
    totalChildren,
    totalAdults,
    diffDays,
    noOfDays,
    setNoOfDays,
    setTotalRooms,
    totalRooms,
    bookTo,
    bookFrom,
    maxGuests,
  } = useBooking()

  const { i18n } = useTranslation();

  const [rooms, setRooms] = useState(null)
  const [promoCode, setPromoCode] = useState(undefined)
  const [isOpenCouponCard, setIsOpenCouponCard] = useState(false)
  const [people, setPeople] = useState({})
  const [loadingProperty, setLoadingProperty] = useState(true)
  const [hotelDetails, setHotelDetails] = useState([])
  const { pathname } = location

  const getLangLink = () => {
    const activeLanguage = i18n?.language.toLowerCase();
    let link = '';
    if(activeLanguage) {
        switch(activeLanguage){
            case 'ja':
                link = '/ja/';
                break;
            case 'zh-hant':
                link = '/zh-hant/';
                break;
            default: link = '/';
        }
    }
    return link;
  }

  function generateRows(list, children, adults) {
    console.log('generating', list, children, adults)
    const groupAdults = []
    const groupChildren = []
    let ad = adults
    let ch = children

    _.map(list.roomTypeID, (id) => {
      if (ch > 0) {
        const a = {
          roomTypeID: id,
          quantity: ad >= 2 ? 1 : ad,
        }
        const c = {
          roomTypeID: id,
          quantity: 1,
        }
        groupAdults.push(a)
        groupChildren.push(c)
        ch -= 1
        ad -= 1
      } else {
        const a = {
          roomTypeID: id,
          quantity: ad >= 2 ? 2 : ad,
        }
        const c = {
          roomTypeID: id,
          quantity: 0,
        }
        ad -= ad >= 2 ? 2 : ad
        groupAdults.push(a)
        groupChildren.push(c)
      }
    })
    if (_.isEmpty(groupChildren)) {
      return {
        children: _.map(groupAdults, (a) => ({
          roomTypeID: a.roomTypeID,
          quantity: 0,
        })),
        adults: groupAdults,
      }
    }
    return {
      children: groupChildren,
      adults: groupAdults,
    }
  }

  const goToPayment = () => {
    const ro = totalRooms.map((room) => console.log("BOOKROOM", room) || ({
      roomTypeID: room.roomId,
      quantity: room.quantity,
      roomTypeName: _.get(room, 'roomInfo.roomTypeName'),
      roomRate: _.get(room, 'roomInfo.roomRate'),
	  roomPhotos: _.get(room, 'roomInfo.roomTypePhotos'),
    }))
    const urlParamsRooms = JSON.stringify(ro)
    const ch = JSON.stringify(people.children)
    const ad = JSON.stringify(people.adults)
    const paramsBooking = {
      rooms: ro,
      startDate: bookFrom,
      endDate: bookTo,
      children: people.children,
      adults: people.adults,
      diffDays,
    }
    sessionStorage.setItem('paramsBooking', JSON.stringify(paramsBooking))
    navigate(`${getLangLink()}checkout/?startDate=${bookFrom}&endDate=${bookTo}&rooms=${encodeURIComponent(urlParamsRooms)}&children=${encodeURIComponent(ch)}&adults=${encodeURIComponent(ad)}`)
    setPeople({})
  }

  // waiting for ther client
  const handlePromoCode = (value) => {
    const headers = {
      'Content-Type': 'application/json',
    }

    makeRequest({
      headers,
      custom: true,
      endPoint: 'getRatePlans',
      params: {
        propertyIDs: process.env.GATSBY_ID_PROPERTY,
        roomTypeID: '',
        promoCode: value.replace(/ /g, ''),
        includePromoCode: true,
        startDate: bookFrom,
        endDate: bookTo,
      },
    }).then((resp) => {
      setPromoCode(resp.data)
    })
  }

  const getHotelDetails = useCallback(async () => {
    setLoadingProperty(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      custom: true,
      endPoint: 'getPropertyInfo',
      params: {
        // lang: i18n.language.toLowerCase(),
      },
    }).then((resp) => {
      setHotelDetails(resp.data)
      setLoadingProperty(false)
    })
  }, [params])

  useEffect(() => {
    getHotelDetails()
    setRooms(_.uniqBy(_.get(roomsAvailables, 'propertyRooms', []), 'roomTypeID'))
  }, [roomsAvailables])

  useEffect(() => {
    const total = _.reduce(totalRooms, (accumulator, currentValue) => {
      if (currentValue.quantity === 2) {
        accumulator.roomTypeID = [...accumulator.roomTypeID, currentValue.roomId, currentValue.roomId]
      } else {
        accumulator.roomTypeID = [...accumulator.roomTypeID, currentValue.roomId]
      }
      accumulator.quantity = currentValue.quantity + accumulator.quantity
      return accumulator
    }, {
      roomTypeID: [],
      quantity: 0,
    })
    const ord = generateRows(total, totalChildren, totalAdults)
    console.log('🚀 ~ file: booking-controller.js ~ line 180 ~ useEffect ~ ord', ord, totalRooms)

    setPeople(ord)
  }, [totalRooms, totalPerson])

  useEffect(() => {
    
    getAvailableRooms(parametres, pathname)
  }, [location])
    
  useEffect(() => {
    console.log("SETNOOFDAYS");
    setNoOfDays(diffDays);
  }, [rooms]);
  console.log('People', people)
  const viewProps = {
    loading,
    loadingProperty,
    hotelDetails,
    roomsAvailables,
    pathname,
    totalPerson,
    diffDays,
    noOfDays,
    setTotalRooms,
    totalRooms,
    handlePromoCode,
    goToPayment,
    maxGuests,
    rooms,
    setIsOpenCouponCard,
    isOpenCouponCard,
    promoCode,
  }
  return (
    <BookingView {...viewProps} />
  )
}

export default BookingController
